body {
  height: 100vh;
}

.lead {
  left: 0;
  width: 100%;
  height: 78vh;
}

.ascii-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

pre.ascii {
  margin: 0;
  text-align: left;
  font-size: 2.4vmin;
  font-family: "JetBrains Mono", "Inconsolata", monospace;
  font-weight: bold;

  i {
    font-style: normal;

    &[c="_"],
    &[c="$"] {
      color: #ff9c00;
    }

    &[c=";"],
    &[c="+"],
    &[c="("],
    &[c=")"],
    &[c="!"],
    &[c="="] {
      color: #fff;
    }

    &[c="["],
    &[c="]"],
    &[c="{"],
    &[c="}"] {
      color: #58cad6;
    }
    &[c="0"],
    &[c="1"],
    &[c="2"],
    &[c="3"],
    &[c="4"],
    &[c="5"],
    &[c="6"],
    &[c="7"],
    &[c="8"],
    &[c="9"] {
      color: #03e421;
    }

    &[c="comment"] {
      color: #fdff8a;
      * {
        color: inherit;
      }
    }

    &[c="comment"] * {
      display: inline;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  &.tdov {
    .d1,
    .d2,
    .d11,
    .d12 {
      i {
        color: #5bcefa !important;
      }
    }

    .d3,
    .d4,
    .d9,
    .d10 {
      i {
        color: #f5a9b8 !important;
      }
    }

    .d5,
    .d6,
    .d7,
    .d8 {
      i {
        color: #ffffff !important;
      }
    }
  }

  &.pride {
    .d1,
    .d2 {
      i {
        color: #ff5755 !important;
      }
    }

    .d3,
    .d4 {
      i {
        color: #ff924c !important;
      }
    }

    .d5,
    .d6 {
      i {
        color: #ffee58 !important;
      }
    }

    .d7,
    .d8 {
      i {
        color: #81e056 !important;
      }
    }

    .d9,
    .d10 {
      i {
        color: #5272fe !important;
      }
    }

    .d11,
    .d12 {
      i {
        color: #9253ff !important;
      }
    }
  }

  &.christmas {
    [c="_"],
    [c="$"] {
      color: #0def42 !important;
    }

    [c=";"],
    [c="+"],
    [c="("],
    [c=")"],
    [c="!"],
    [c="="] {
      color: #fff !important;
    }

    [c="["],
    [c="]"],
    [c="{"],
    [c="}"] {
      color: #17c867 !important;
    }
    [c="0"],
    [c="1"],
    [c="2"],
    [c="3"],
    [c="4"],
    [c="5"],
    [c="6"],
    [c="7"],
    [c="8"],
    [c="9"] {
      color: #dc3d2a !important;
    }
  }
}

.ascii {
  .delay {
    display: inline-block;
    overflow: hidden;
    animation: type 0.2s steps(60, end);
    animation-fill-mode: backwards;
    line-height: 1.35;

    &.d1 {
      animation-delay: 0.5s;
    }
    &.d2 {
      animation-delay: 0.7s;
    }
    &.d3 {
      animation-delay: 0.9s;
    }
    &.d4 {
      animation-delay: 1.1s;
    }
    &.d5 {
      animation-delay: 1.3s;
    }
    &.d6 {
      animation-delay: 1.5s;
    }
    &.d7 {
      animation-delay: 1.7s;
    }
    &.d8 {
      animation-delay: 1.9s;
    }
    &.d9 {
      animation-delay: 2.1s;
    }
    &.d10 {
      animation-delay: 2.3s;
    }
    &.d11 {
      animation-delay: 2.5s;
    }
    &.d12 {
      animation-delay: 2.7s;
    }
    &.d13 {
      animation-delay: 2.9s;
    }
    &.d14 {
      animation-delay: 3.1s;
    }
    &.d15 {
      animation-delay: 3.3s;
    }
    &.d16 {
      animation-delay: 3.5s;
    }
    &.d17 {
      animation-delay: 3.7s;
    }
    &.d18 {
      animation-delay: 3.9s;
    }
    &.d19 {
      animation-delay: 4.1s;
    }
    &.d20 {
      animation-delay: 4.3s;
    }
    &.d21 {
      animation-delay: 4.5s;
    }
  }
  abbr[title] .delay {
    line-height: 1;
  }
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
